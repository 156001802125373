import React from "react"
import styles from "./success-order-success-page.module.scss"
import SubscribeOverlayService from "../../../../services/subscribe-overlay-service"
import Banner from "../../../../components/pages/partners/banner"
import arrowIcon from '../../../../images/icons/icon-social-arrow-right.svg'


const SuccessOrderSuccessPage = () => (
  <div className={styles.main}>
    <div className={`container ${styles.container}`}>
      <h2 className={styles.heading}>Полезные материалы для быстрой и удобной <br /> работы в Параплан </h2>
      <div className={styles.links}>
        <div className={styles.link}>
          <p className={styles.text}>Видеоинструкции</p>
          <a className={styles.green} target={"_blank"} href={"https://www.youtube.com/channel/UCHyuMKQS-MQeJTS00YcPo6g"}>Смотреть</a>
        </div>
        <div className={styles.link}>
          <p className={styles.text}>Чек-лист настройки системы</p>
          {/* <!--<button className={styles.blue} onClick={() => SubscribeOverlayService.open()}>Скачать</button> */}
          <a target={"_blank"} className={styles.blue} href='/files/setup_checklist.pdf'>Скачать</a>
        </div>
      </div>
    </div>
    <Banner className={styles.banner} />
    <div className={styles.actual}>
      <div className={`container ${styles.container}`}>
        <div className={styles.socials}>
          <div className={styles.social}>
            <h2 className={styles.subheading}>Самые актуальные новости в наших группах</h2>
            <div className={styles.icon}>
              <img src={arrowIcon} alt="" />
            </div>
            <div className={styles.icon}>
              <img src={arrowIcon} alt="" />
            </div>
          </div>
          <div className={styles.socialwrapper}>
            <a className={styles.vkBtn} href="https://vk.com/paraplancrm" target="_blank">Группа ВКонтакте</a>
            <a className={styles.tgBtn} href="https://t.me/ParaplanCRMnews" target="_blank">Telegram канал</a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SuccessOrderSuccessPage
