import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import React from "react"
import HeroOrderSuccessPage from "../../components/pages/shared/hero/order-success-page"
import SocialOrderSuccessPage from "../../components/pages/success-page-social/order-success-page"

const OrderSuccessPage = () => (
  <Layout page={"orderSuccess"}>
    <SEO title="Спасибо за покупку" keywords={[`crm-система`, `учет клиентов`]}/>
    <HeroOrderSuccessPage/>
    <SocialOrderSuccessPage/>
    <ContactFormComponent color={"#F9F9FA"}/>
  </Layout>
)

export default OrderSuccessPage
