import React from "react"
import styles from "./hero-order-success-page.module.scss"

const HeroOrderSuccessPage = () => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}>Спасибо за покупку</h1>
      <p className={styles.subheading}>Сообщите специалисту Службы заботы о факте оплаты в чат, чтобы он <br/> открыл или продлил Вам доступ.</p>
      <a className={styles.cta} target={"_blank"} href={"/crm"}>Перейти в систему</a>
    </div>
  </div>
)

export default HeroOrderSuccessPage
