import React from "react"
import styles from "./banner.module.scss"

const PartnersHowItWork = () => (
  <div className={styles.banner}>
    <div className={`container ${styles.container}`}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>Клиент Параплана?</h2>
        <h3 className={styles.subtitle}>
          Получите
          <br /> <span>МЕСЯЦ В ПОДАРОК</span> <br />
          за покупку по рекомендации
        </h3>
        <p className={styles.text}>Порекомендуйте Параплан своим друзьям и знакомым.<br/>
          В случае покупки по рекомендации получите месяц пользования системой БЕСПЛАТНО.</p>
      </div>
    </div>
  </div>
)

export default PartnersHowItWork
